import { render, staticRenderFns } from "./WalletTransactionTable.vue?vue&type=template&id=17bc0088&scoped=true&"
import script from "./WalletTransactionTable.vue?vue&type=script&lang=js&"
export * from "./WalletTransactionTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17bc0088",
  null
  
)

export default component.exports